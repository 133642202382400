<template>
  <div class="o-check-code check-code">
    <div class="check-code__content">
      <h3 v-if="!simple" class="check-code__title">
        {{ title }}
      </h3>
      <template v-if="loginViaPIN">
        <p v-if="!simple" class="auth-summary">
          {{ $t('Login by number') }}
        </p>
        <div v-if="!simple" class="phone-block">
          <div class="phone">
            {{ phone }}
          </div>
          <div
            data-transaction-name="Edit Check Code - Phone"
            @click="edit"
            class="edit"
          >
            {{ $t('Edit') }}
          </div>
        </div>
        <div class="enter-pin" :class="{ 'enter-pin--simple': simple }">
          {{ $t('Enter PIN') }}

          <div
            v-if="simple"
            data-transaction-name="Show Password"
            @click="showPassword"
            class="show"
          >
            <div v-if="showPass">
              {{ $t('Hide') }}
            </div>
            <div v-else>
              {{ $t('Show') }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <p v-if="!simple" class="auth-summary">
          {{ $t('SMS with the code has been sent to the number') }}
        </p>
        <div v-if="!simple" class="phone-block">
          <div class="phone">
            {{ phone }}
          </div>
          <div
            data-transaction-name="Edit Check Code - Phone"
            @click="edit"
            class="edit"
          >
            {{ $t('Edit') }}
          </div>
        </div>
        <div class="enter-pin">
          {{ $t('SMS code') }}
        </div>
      </template>
      <form class="form">
        <div v-if="isLoading" class="pin-loader">
          <SfLoader :loading="isLoading" />
        </div>
        <template v-else>
          <no-ssr>
            <CodeInput
              v-if="loginViaPIN"
              :loading="false"
              type="tel"
              :auto-focus="true"
              :field-width="fieldWidth"
              :field-height="fieldHeight"
              :fields="4"
              class="pin-code"
              @complete="onComplete"
              @change="onChange"
              ref="code-input-pin"
              :class="{ 'wrong-code-input': isCodeWrong, 'password-input': !showPass }"
              :readonly="showLoginLoader"
            />
            <CodeInput
              v-else
              :loading="false"
              type="tel"
              :field-width="fieldWidth"
              :field-height="fieldHeight"
              :fields="4"
              class="pin-code"
              @complete="onComplete"
              @click.native="onChange"
              ref="code-input-pin"
              :class="{ 'wrong-code-input': isCodeWrong }"
              :readonly="showLoginLoader"
            />
          </no-ssr>
          <div v-if="isCodeWrong && !anotherError" class="wrong-code">
            {{ $t('Wrong code') }}
          </div>
          <div v-else-if="anotherError" class="wrong-code">
            {{ anotherError }}
          </div>
        </template>
        <template v-if="loginViaPIN">
          <div class="action-block" :class="{'action-block--simple': simple}">
            <div>
              <SfButton
                v-if="simple"
                @click.prevent="login"
                data-transaction-name="Auth - Login with pin - Action"
                class="sf-button--primary sf-button--full-width-mobile form__submit action-block__submit"
              >
                {{ $t("Login with PIN code") }}
              </SfButton>
            </div>

            <div>
              <span
                class="forgot-pass"
                data-transaction-name="Forgot Pin Code"
                @click="onForgotPinClick"
              >
              {{ $t('Login with SMS code') }}
            </span>
            </div>

            <div
              v-if="!simple"
              data-transaction-name="Show Password"
              @click="showPassword"
              class="show"
            >
              <div v-if="showPass">
                {{ $t('Hide') }}
              </div>
              <div v-else>
                {{ $t('Show') }}
              </div>
            </div>
          </div>
          <div
            v-if="!simple"
            data-transaction-name="Login with SMS"
            @click="openSMSModal"
            class="login-via-sms"
          >
            {{ $t('Login with SMS code') }}
          </div>
        </template>
        <div
          v-else
          class="send-sms-wrap"
        >
          <div
            v-if="canSendSms"
            data-transaction-name="Send code again"
            @click="sendSms"
            class="send-again"
          >
            {{ $t('Send code again') }}
          </div>
          <div
            v-else
            class="timer"
          >
            <div v-if="currentLocale !== 'ru-RU' && currentLocale !== 'uk-UA'">
              {{ $t('You can send SMS again after {seconds} seconds', { seconds: waitSmsSeconds }) }}
            </div>
            <div v-else>
              {{ $tc('You_can_send_SMS_again_after_seconds', waitSmsSeconds, { count: waitSmsSeconds }) }}
            </div>
          </div>
        </div>
      </form>
    </div>
    <ALoadingSpinner
      key="spinner"
      class=""
      v-show="showLoginLoader"
      :is-absolute-position="true"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ModalList } from 'theme/store/ui/modals'
import SfLoader from 'src/themes/varus/components/storefront-override/SfLoader'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';
import CodeInput from 'theme/components/molecules/m-pin-input'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { Logger } from '@vue-storefront/core/lib/logger'
import i18n from '@vue-storefront/i18n';
import DeviceType from 'theme/mixins/DeviceType';
import NoSSR from 'vue-no-ssr';
import ReCaptcha from 'theme/mixins/ReCaptcha';
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { eSputnikEvent } from 'theme/helpers/es';
import { isServer } from '@vue-storefront/core/helpers';
import { SfButton } from '@storefront-ui/vue';

export default {
  name: 'OCheckCode',
  mixins: [DeviceType, ReCaptcha],
  components: {
    SfButton,
    CodeInput,
    SfLoader,
    ALoadingSpinner,
    'no-ssr': NoSSR
  },
  props: {
    phone: {
      type: String,
      default: ''
    },
    validStatus: {
      type: String,
      default: ''
    },
    flag: {
      type: String,
      default: 'sms'
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    clientStateSave: {
      type: Boolean,
      default: () => false
    },
    simple: {
      type: Boolean,
      default: () => false
    },
    runTimer: {
      type: Boolean,
      default: () => false
    },
    events: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      forgotPasswordData: false,
      loginViaPIN: null,
      pinCode: '',
      smsCode: '',
      showPass: false,
      currentLocale: 'uk-UA',
      fieldHeight: 56,
      isCodeWrong: false,
      anotherError: null,
      isLoading: false,
      showLoginLoader: false,
      needToCaptchaCheck: false
    };
  },
  beforeDestroy () {
    if (this.$recaptchaInstance !== undefined) {
      this.$recaptchaInstance.hideBadge()
    } else Logger.error('recaptchaInstance is undefined', 'recaptcha')()
  },
  beforeMount () {
    this.initCaptcha()
  },
  computed: {
    ...mapState({
      waitSmsSeconds: state => state.ui.waitSmsSeconds,
      canSendSms: state => state.ui.canSendSms,
      currentUser: (state) => state.user.current
    }),
    isNewUser () {
      return this.validStatus === 'not_found'
    },
    fieldWidth () {
      return this.isMobile ? 76 : 81
    },
    title () {
      return this.flag === 'pin' && this.loginViaPIN ? this.$t('PIN code') : this.$t('SMS code')
    }
  },
  mounted () {
    this.loginViaPIN = this.flag === 'pin'
    this.currentLocale = currentStoreView().i18n.defaultLocale
    this.setKeyboardAttributs()
    this.setFocusOnTheFirstInput()
  },
  watch: {
    loginViaPIN () {
      this.setFocusOnTheFirstInput()
    },
    flag () {
      this.loginViaPIN = this.flag === 'pin'
    },
    isVisible () {
      this.setFocusOnTheFirstInput()
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal',
      sendOtp: 'userInfo/sendOtp',
      userLogin: 'user/login'
    }),
    checkCodeOpen (data) {
      if (this.events) return this.$emit('check-code-open', data)

      this.openModal({ name: ModalList.OModalCheckoutCheckCode, payload: data })
    },
    checkCodeClose () {
      if (this.events) return this.$emit('check-code-close')

      this.closeModal({ name: ModalList.OModalCheckoutCheckCode });
    },
    waitBeforeSendSms () {
      EventBus.$emit('wait-before-send-sms')
    },
    getCodeInputFirstElement () {
      return this.$refs['code-input-pin']?.$el?.children[0]?.children[0] || null
    },
    setKeyboardAttributs () {
      try {
        const refs = Object.keys(this.$refs['code-input-pin'].$refs || {})

        for (const ref of refs) {
          const [element] = this.$refs['code-input-pin'].$refs?.[ref]

          element?.setAttribute('pattern', '[0-9]*')
          element?.setAttribute('inputmode', 'numeric')
        }
      } catch (e) {}
    },
    setFocusOnTheFirstInput () {
      const element = this.getCodeInputFirstElement()
      if (element) {
        this.$nextTick(() => {
          element.focus()
        });
      }
    },
    async sendSms (needToChangePin = false) {
      try {
        if (this.forgotPasswordData) {
          this.checkCodeOpen(this.forgotPasswordData)
          return
        }

        if (!this.canSendSms) return

        this.isLoading = true
        this.isCodeWrong = false
        this.anotherError = null
        const response = await this.sendOtp(this.phone.split(' ').join(''))
        this.isLoading = false

        if (response.code !== 200) {
          sendToLogs(
            groups.Auth,
            'otp:oCheckCode:sendSms:not:200',
            {
              code: response.code,
              result: response?.result || null
            },
            response?.transparentId
          )

          this.onFailure({ result: this.$t('Unexpected error when sending a code by SMS') })
        } else if (needToChangePin) {
          this.waitBeforeSendSms()

          this.forgotPasswordData = {
            phone: this.phone,
            status: this.status,
            flag: 'sms',
            clientStateSave: this.clientStateSave
          }

          this.checkCodeOpen(this.forgotPasswordData)
        }
      } catch (error) {
        this.isLoading = false
        this.waitBeforeSendSms()
        Logger.error(error, 'sendOtp')()
        this.onFailure({ result: this.$t('Unexpected authorization error. Check your Network connection.') })

        sendToLogs(
          groups.Auth,
          'otp:oCheckCode:sendSms:catch:error',
          { message: error.message }
        )
      }
    },
    async login () {
      const code = this.loginViaPIN ? this.pinCode : this.smsCode

      if (code.length !== 4) {
        this.isCodeWrong = true
        this.anotherError = this.$t('Enter the password code')
        return
      }

      this.showLoginLoader = true
      let captchaToken = ''

      if (this.needToCaptchaCheck) {
        if (this.$recaptchaInstance !== undefined) {
          captchaToken = await this.$recaptcha('login')
        } else {
          Logger.error('recaptchaInstance is undefined', 'recaptcha')()

          sendToLogs(
            groups.Auth,
            `otp:${this.loginViaPIN ? 'pin' : 'sms'}:oCheckCode:recaptcha`,
            { recaptchaUndefined: true }
          )
        }
      }
      this.needToCaptchaCheck = false

      try {
        const response = await this.userLogin({
          username: this.phone.split(' ').join(''),
          password: code,
          showLoader: false,
          isNewUser: this.isNewUser,
          clientStateSave: this.clientStateSave,
          captchaToken
        })

        this.showLoginLoader = false
        if (response.code === 200) {
          if (this.isNewUser) {
            this.onSuccess(this.$t('You are registered and logged in!'));
          } else {
            this.onSuccess(this.$t('You are logged in!'));
          }

          eSputnikEvent('CustomerData', this.currentUser);
          this.checkCodeClose()
          return
        } else {
          if (response.result?.error_code === 'invalid_password') {
            this.isCodeWrong = true
            this.needToCaptchaCheck = true

            if (this.$recaptchaInstance !== undefined) {
              this.$recaptchaInstance.showBadge()
            } else Logger.error('recaptchaInstance is undefined', 'recaptcha')()
          } else if (response.result?.message) {
            this.anotherError = response.result?.message || ''
          }

          this.isCodeWrong = true
        }

        sendToLogs(
          groups.Auth,
          `otp:${this.loginViaPIN ? 'pin' : 'sms'}:oCheckCode:login:not:200`,
          { code: response.code, result: response?.result || null },
          response?.transparentId
        )
      } catch (error) {
        this.showLoginLoader = false
        this.isCodeWrong = false
        this.anotherError = null
        Logger.error(error, 'login')()
        this.onFailure({ result: error })

        sendToLogs(
          groups.Auth,
          `otp:${this.loginViaPIN ? 'pin' : 'sms'}:oCheckCode:login:catch:error`,
          { message: error?.message || error }
        )
      } finally {
        this.smsCode = ''
        this.pinCode = ''
      }
    },
    showPassword () {
      this.showPass = !this.showPass
    },
    edit () {
      this.$emit('close')
    },
    openSMSModal () {
      if (isServer) return
      this.isLoading = true
      this.loginViaPIN = false
      this.smsCode = ''
      this.pinCode = ''
      this.isCodeWrong = false
      this.anotherError = null
      setInterval(() => { this.isLoading = false }, 1)
      this.$store.dispatch('userInfo/sendOtp', this.phone.split(' ').join(''))
      const data = {
        phone: this.phone,
        flag: 'sms',
        clientStateSave: this.clientStateSave
      }

      this.checkCodeOpen(data)
    },
    onComplete (value) {
      if (this.loginViaPIN) {
        this.pinCode = value
      } else {
        this.smsCode = value
      }
      this.isCodeWrong = false
      this.anotherError = null

      this.login()
    },
    onChange () {
      this.isCodeWrong = false
      this.anotherError = null
    },
    onSuccess (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'account',
        message: message
      });
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'danger',
        message: i18n.t(result.result),
        action1: { label: i18n.t('OK') }
      });
    },
    onForgotPinClick () {
      this.sendSms(true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/third-party-components/vue-verification-code-input";

.check-code {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0;

  @media only screen and (min-width: $tablet-min) {
    justify-content: flex-start;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--font-sm);
    line-height: 17px;
  }

  &__title {
    display: none;
    @media only screen and (min-width: $tablet-min) {
      display: inline-block;
      font-size: var(--font-size-24);
      font-weight: var(--font-medium);
      line-height: var(--font-size-25);
      margin: 0 0 var(--spacer-20);
    }
  }
}

.wrong-code {
  color: red;
  margin-top: -15px;
  margin-bottom: var(--spacer-12);
}

.form {
  width: 100%;

  &__element {
    margin: var(--spacer-base) 0;
  }

  &__checkbox {
    margin: var(--spacer-xl) 0 var(--spacer-2xl) 0;
  }

  &__submit {
    margin: var(--spacer-xl) 0 0 0;
  }
}

.is-no-error-massage {
  ::v-deep .sf-input__error-message {
    display: none;
  }
}

.auth-summary {
  font-size: var(--font-sm);
  font-weight: var(--font-normal);
  line-height: var(--font-size-17);
  text-align: center;
  color: var(--black);
  margin: 0 0 var(--spacer-16);
}

.phone-block {
  display: flex;
  font-size: var(--font-sm);
  line-height: var(--font-size-17);
  margin: 0 0 var(--spacer-30);

  .phone {
    margin-right: var(--spacer-20);
  }

  .edit {
    cursor: pointer;
    color: var(--orange)
  }
}

.enter-pin {
  margin-bottom: var(--spacer-11);
  text-align: center;

  &--simple {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: var(--spacer-10);
    margin-top: var(--spacer-24);

    .show {
      color: var(--orange);
    }
  }

  @media only screen and (min-width: $tablet-min) {
    margin-bottom: var(--spacer-14);
  }
}

.action-block {
  display: flex;
  justify-content: space-between;
  color: var(--orange);
  margin-bottom: 43px;

  &--simple {
    flex-direction: column;
    margin-bottom: 0;
  }

  &__submit {
    &:disabled {
      --button-background: var(--orange-disabled);
      --button-color: var(--white);
    }

    margin-top: 0;
    margin-bottom: var(--spacer-10);
  }
}

.forgot-pass {
  cursor: pointer;
}

.login-via-sms {
  color: var(--orange);
  text-align: center;
  cursor: pointer;
}

.show {
  cursor: pointer;
}

.send-sms-wrap {
  margin: 0 0 var(--spacer-20);
}

.send-again {
  text-align: center;
  cursor: pointer;
  color: var(--orange);
}

.timer {
  color: var(--dark-gray);
  font-size: var(--font-size-13);
  text-align: center;
  max-width: 255px;
  margin: 0 auto;

  @media (max-width: $mobile-max) {
    margin: 0;
    max-width: 100%;

    div:first-child {
      text-align: left;
    }
  }
}

::v-deep {
  .sf-input {
    --input-label-top: 23%;

    input {
      &:focus {
        & ~ * {
          --input-label-top: 23%;
        }
      }
    }
  }
}

.pin-loader {
  padding: var(--spacer-40) 0 var(--spacer-50);
}
</style>
